import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export function initLaravelEcho() {
    window.Pusher = Pusher;

    window.Echo = new Echo({
        broadcaster: import.meta.env.VITE_BROADCAST_DRIVER,
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        wsHost: window.location.hostname,
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        forceTLS: false,
    });
}
