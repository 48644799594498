import '@node_modules/es6-shim/es6-shim.js';
import "@node_modules/es6-promise-polyfill/promise.min.js";

import $ from "@node_modules/jquery/dist/jquery.js";
import * as popperjs from "@node_modules/@popperjs/core/dist/esm/popper.js";
import bootstrap from "@node_modules/bootstrap/dist/js/bootstrap.min.js";
import moment from "@node_modules/moment/min/moment-with-locales.min.js";

import wnumb from "@node_modules/wnumb/wNumb.js";
import { initLaravelEcho } from './echo.js';

window.$ = window.jQuery = $;
window.bootstrap = bootstrap;
window.Popper = popperjs;
window.moment = moment;


window.wNumb = wnumb;

initLaravelEcho();
